//import { Link } from 'gatsby';
import React from 'react';
import PromoteCta from '../common/PromoteCta';
import LearnCta from './LearnCta';
import ReadDiaryCta from './ReadDiaryCta';

export default function FooterNav() {
  return (
    <div className=' w-full'>
      {/* Faiths Diary */}
      <ReadDiaryCta />
      {/* Faith deserves to be heard */}
      <PromoteCta />
      {/* Learn more about the project */}
      <LearnCta />
    </div>
  );
}
