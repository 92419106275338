import React from 'react';
import { Link } from 'gatsby';

function LearnCta() {
  return (
    <div className='grid grid-cols-2 w-full'>
      <div className='col-span-2 bg-white px-8'>
        <div className='pt-16 pb-8 md:pt-24 md:pb-16 text-center max-w-3xl m-auto'>
          <h4 className='text-4xl md:text-6xl leading-none  font-serif  '>
            Learn more about the project
          </h4>
          <p className='py-8'>
            This project was created by a team from Focus Ireland for the Regal
            EU research project. Learn more about the project by visiting one of
            these pages.
          </p>
        </div>
      </div>
      {/* How We Created Faith */}
      <Link className='col-span-2 ' to='/creating-faith'>
        <div className='bg-faith-green  '>
          <div className='py-16 md:py-32'>
            <h4 className='text-2xl md:text-5xl text-center font-bold text-white'>
              How We Created Faith
            </h4>
          </div>
        </div>
      </Link>
      {/* About Regal */}
      <Link className='col-span-2 md:col-span-1 ' to='/about-regal'>
        <div className='bg-faith-orange  '>
          <div className='py-16 md:py-32'>
            {' '}
            <h5 className='text-2xl md:text-5xl text-center font-bold  text-white'>
              About Regal
            </h5>
          </div>
        </div>
      </Link>
      {/* About Focus */}
      <Link className='col-span-2 md:col-span-1 ' to='/about-focus'>
        <div className='bg-faith-yellow '>
          <div className='py-16 md:py-32'>
            {' '}
            <h5 className='text-2xl md:text-5xl text-center font-bold  text-white'>
              About Focus Ireland
            </h5>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default LearnCta;
