import React from 'react';
import { Link } from 'gatsby';

function ReadDiaryCta() {
  return (
    <div className='col-span-2 bg-faith-green px-8 diary-entry-bg bg-right md:bg-center'>
      <div className='py-32 text-center max-w-3xl m-auto'>
        <h4 className='text-4xl md:text-6xl font-serif day-heading-bg'>
          Faith&apos;s Diary
        </h4>
        <p className='py-8 max-w-sm mx-auto bg-faith-green'>
          Faith wrote a diary for one week, and she wants to share it with you.
        </p>

        <Link to='/diary' className='faith-btn'>
          <button className='faith-btn'>
            <span> Go to Faith&apos;s Diary</span>
          </button>{' '}
        </Link>
      </div>
    </div>
  );
}

export default ReadDiaryCta;
