import React from 'react';

const About = () => {
  return (
    <section id='about' className='bg-white w-full min-h-screen '>
      <div className='py-16 md:py-32   md:w-2/3  mx-auto px-8  md:px-0'>
        <header className='text-4xl md:text-6xl font-serif text-center mb-8 md:mt-0 alt-heading-bg'>
          <h2>About</h2>
        </header>
        <div className='content max-w-3xl mx-auto space-y-4 md:text-xl'>
          <p>
            {' '}
            Life is difficult for many women in Ireland as they try to balance
            their role as a parent or carer with the many other responsibilities
            expected of them. The demands of life seem infinite. And our
            resources seem scarce. This can lead to seriously stressful
            situations where women’s health and wellbeing suffers. When women
            are lone parents, stress and suffering can be felt all the more.
          </p>

          <p>
            This website gives insight into the difficulties faced by many lone
            parents. Here, we meet Faith, a lone parent of three children,
            previously homeless, who shares her diary entries for one week.
            Faith is a fictional character who has been created from the{' '}
            <a href='/creating-faith' className='faith-link'>
              stories of real women
            </a>
            . Faith’s experiences are genuine and they are currently being lived
            by women all around Ireland.
          </p>

          <p>
            In sharing these experiences, we wish to highlight the challenge met
            by women like Faith. These are the women who find themselves locked
            in a battle for time, security and dignity. These are the women who,
            despite their limited resources, work hard to raise their children.
          </p>

          <p>
            We believe that women like Faith should not have such limited
            resources. The policy-makers in our country make active choices that
            shape our lives. And there are many inequalities that need to be
            addressed swiftly.
          </p>
          <p>
            If you share this opinion, please share Faith’s story. In doing
            this, you will highlight the many inequalities that need to be
            overcome. Work with us to stop Testing Faith, and all the women she
            represents.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
