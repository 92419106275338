import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

function ProjectCta() {
  return (
    <div className='my-2 z-0'>
      <AnchorLink to='/#about' title='Read more'>
        <button className='faith-btn text-base'>
          <span className=''>Learn more</span>
        </button>
      </AnchorLink>
    </div>
  );
}

export default ProjectCta;
