import React from 'react';
import FooterNav from '../components/common/FooterNav';
import About from '../components/index/About';

//import Introduction from '../components/index/Introduction';

import Hero from '../components/illustrations/Hero';

import ProjectCta from '../components/index/ProjectCta';

import Layout from '../components/layout';
import SEO from '../components/seo';

function IndexPage() {
  return (
    <Layout bg='bg-faith-yellow '>
      <SEO
        keywords={[
          `work-life balance`,
          `parent support`,
          `lone parent`,
          `childcare`,
          'ireland',
        ]}
        title='Testing Faith'
      />

      <section className=' h-screen w-full landing-heading-bg bg-top  md:bg-opacity-100  md:bg-center  pb-16 md:py-24 '>
        <div className='h-full flex flex-col  justify-center md:justify-end mx-auto text-center '>
          {/*}
          <div>
            
            <Introduction />
          </div>
  {*/}
          <div className='block w-full md:hidden  '>
            <Hero className='' />
          </div>
          <div className='text-black text-lg md:text-2xl max-w-xl mx-auto px-8'>
            <p>
              Faith is a proud mother of three children but sometimes feels more
              like a gerbil on a wheel...{' '}
            </p>
            <div className='p-'>
              <ProjectCta />
            </div>
          </div>
        </div>
      </section>
      <About />
      <FooterNav />
    </Layout>
  );
}

export default IndexPage;
